import { Bars4Icon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { ListViewType } from "components/typings";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  view: ListViewType;
  onSwitchView: (view: ListViewType) => void;
}

const ListViewSwitch = ({ view, onSwitchView }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "common.view" });
  return (
    <div className="text-color-secondary ml-6 mt-1 flex items-center rounded-lg bg-gray-100 p-0.5 sm:mt-0 dark:bg-slate-700">
      <button
        onClick={() => onSwitchView("table")}
        type="button"
        title={t("table")}
        data-test="btn-toggle-view-table"
        data-dd-action-name="btn-toggle-view-table"
        className={cn(
          "rounded-md p-1.5",
          view === "table"
            ? "offset-ring-300 bg-ribbon-500 text-white hover:bg-ribbon-400"
            : "offset-ring-500 bg-gray-100 text-gray-400 dark:bg-slate-700 dark:text-slate-400 dark:hover:bg-slate-600"
        )}
      >
        <Bars4Icon className="size-5" aria-hidden="true" />
        <span className="sr-only">{t("table")}</span>
      </button>
      <button
        onClick={() => onSwitchView("card")}
        type="button"
        title={t("card")}
        data-test="btn-toggle-view-card"
        data-dd-action-name="btn-toggle-view-card"
        className={cn(
          "ml-0.5 rounded-md p-1.5 shadow-sm",
          view === "card"
            ? "offset-ring-300 bg-ribbon-500 text-white hover:bg-ribbon-400"
            : "offset-ring-500 bg-gray-100 text-gray-400 dark:bg-slate-700 dark:text-slate-400 dark:hover:bg-slate-600"
        )}
      >
        <Squares2X2Icon className="size-5" aria-hidden="true" />
        <span className="sr-only">{t("card")}</span>
      </button>
    </div>
  );
};

export default ListViewSwitch;
