import { AnonymousXtmJobLinksTable } from "anonymous/pages/AnonymousXtmJobLinksTable";
import DeliverAnonymousXtmTaskModal from "anonymous/pages/DeliverAnonymousXtmTaskModal";
import { useAnonymousXtmData, useAnonymousXtmTask } from "anonymous/query/xtm.task.query";
import Badge from "components/Badge";
import Loader from "components/Loader";
import Button from "components/buttons/Button";
import PartnerPortalLogo from "components/svgs/Logo";
import { Separator } from "components/ui/separator";
import PageFooter from "layout/PageFooter";
import { Loader2Icon } from "lucide-react";
import { XtmSegments } from "pages/project/common/taskBase/list/XtmSegments";
import { TaskBaseDetail } from "pages/project/common/taskBase/sidebar/TaskBaseDetail";
import { TaskStatus } from "pages/project/task/task.typing";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

const XdmXtmSegmentsComponent = ({ token }: { token: string }) => {
  const { steps, isFetching } = useAnonymousXtmData(token, true);
  if (isFetching) {
    return <Loader2Icon className="size-4 shrink-0 animate-spin" />;
  }
  return steps ? <XtmSegments xtmSteps={steps} /> : null;
};

export const AnonymousXtmTask = () => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isDelivered, setIsDelivered] = useState<boolean>(false);
  const { token = "" } = useParams<{
    token: string;
  }>();
  const { t } = useTranslation();
  const { isLoading, selectedTask } = useAnonymousXtmTask(token);

  if (!selectedTask) {
    return null;
  }

  if (isLoading) {
    return <Loader isShown={true} />;
  }
  if (!selectedTask.id) {
    return <Navigate to="/error/404" replace={true} />;
  }

  const isTaskOngoing = selectedTask.status === TaskStatus.OnGoing;

  return (
    <>
      <div className="mx-auto flex min-h-full max-w-4xl flex-col p-4">
        {/*header*/}
        <div className="flex shrink-0 flex-wrap items-center justify-between gap-4 py-2">
          <div className="w-64">
            <PartnerPortalLogo size="normal" />
          </div>
          {isTaskOngoing ? (
            <div>
              <Button
                disabled={isDelivered}
                className=""
                color="primary"
                dataTestId="btn-deliver-task"
                label={t("task.detail.deliver")}
                onClick={() => setShowConfirm(true)}
              />
            </div>
          ) : null}
        </div>
        {/*body*/}
        {isTaskOngoing ? <XdmXtmSegmentsComponent token={token} /> : null}
        <div className="mb-6 mt-2 flex flex-1 flex-col">
          <div className="rounded border border-gray-300 px-2 py-4 dark:border-slate-600 ">
            <div className="flex flex-1 flex-wrap items-center gap-2">
              <h2 className="text-color-primary h-full px-3 text-3xl font-medium">{selectedTask.taskCode}</h2>
              <Badge className="px-2 capitalize">{selectedTask.taskType}</Badge>
            </div>
            <Separator className="mt-4 dark:bg-slate-700" />
            <TaskBaseDetail
              isUserSupplier={false}
              isCanEditSupplierContact={false}
              selectedTaskBase={selectedTask}
              taskBaseStartDateLabel={t("task-base.detail.startDate")}
            />
            <Separator className="mt-4 dark:bg-slate-600" />
            <AnonymousXtmJobLinksTable token={token} isTaskOngoing={isTaskOngoing} />
          </div>
        </div>

        <PageFooter />
      </div>
      {selectedTask ? (
        <DeliverAnonymousXtmTaskModal
          token={token}
          show={showConfirm}
          onClose={() => {
            setShowConfirm(false);
          }}
          onConfirm={() => {
            setShowConfirm(false);
            setIsDelivered(true);
          }}
        />
      ) : null}
    </>
  );
};
