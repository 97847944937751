import { MemSourceProjectLinkDto, TaskBaseDto, TaskBaseFileDto } from "api/api.typing";

export type TaskBaseFields = keyof TaskBase;

export interface TaskBase extends Omit<TaskBaseDto, "id" | "unit" | "secondUnit"> {
  id: string; // convert to string, easier to manage in react-router
  searchFields: string;
  unit: string;
  secondUnit: string;
  supplierContactName: string;
}

export type TaskBaseTypes = "task" | "offer" | "request";

export type TaskBaseFile = TaskBaseFileDto;

export type MemSourceProjectLink = MemSourceProjectLinkDto;

export interface XtmSteps {
  totalSegments: number;
  doneSegments: number;
  progression: number;
  details: Array<{
    documentName: string;
    totalSegments: number;
    doneSegments: number;
    progression: number;
  }>;
}

export const CAT_TOOLS: Array<{ id: number; name: string }> = [
  { id: 1, name: "Trados Studio" },
  { id: 2, name: "Memsource" },
  { id: 3, name: "Xtm" },
  { id: 4, name: "Across" },
  { id: 5, name: "Translate5" },
];

export const getCatToolName = (catToolId: number): string | null =>
  CAT_TOOLS.find((ct) => ct.id === catToolId)?.name ?? null;

export const PHRASE_CAT_TOOL_ID = 2;
export const XTM_CAT_TOOL_ID = 3;
export const TRANSLATE5_CAT_TOOL_ID = 5;
export interface QAFormType {
  ratings: Array<{ key: number; value: string }>;
  titles: Array<{ key: number; value: string }>;
}

export type TaskFileUploadType = "delivery" | "notes";

export enum QualitySurveyRatingStarAppreciation {
  OneStar = 137,
  TwoStars = 138,
  ThreeStars = 139,
  FourStars = 140,
  FiveStars = 141,
}
