import { BarsArrowDownIcon } from "@heroicons/react/24/outline";
import { TaskBaseFileDto } from "api/api.typing";
import { AxiosError } from "axios";
import Collapse from "components/Collapse";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { FileBoxIcon, NotebookPen } from "lucide-react";
import { useDeliveredFileList } from "query/task.query";
import { useTranslation } from "react-i18next";
import { TaskFileUploadType } from "../../taskBase.typing";

interface Props {
  taskId: string;
}

export const FileListTable = ({ taskId }: Props) => {
  const { files, hasDeliveryFiles, hasNoteFiles, isFetching, error } = useDeliveredFileList(taskId);

  if (isFetching) {
    return <Loader isShown={true} />;
  }

  return (
    <>
      <FileListExpandable
        fileType="delivery"
        files={files.filter((f) => f.fileType.toLowerCase() === "delivery")}
        hasFiles={hasDeliveryFiles}
        error={error}
      ></FileListExpandable>
      <FileListExpandable
        fileType="notes"
        files={files.filter((f) => f.fileType.toLowerCase() === "notes")}
        hasFiles={hasNoteFiles}
        error={error}
      ></FileListExpandable>
    </>
  );
};

interface FileListExpandableProps {
  fileType: TaskFileUploadType;
  files: TaskBaseFileDto[];
  hasFiles: boolean;
  error: AxiosError | null;
}

const FileListExpandable = ({ fileType, files, hasFiles, error }: FileListExpandableProps) => {
  const { t } = useTranslation();

  const fileTypeKey = fileType === "delivery" ? "delivery-files" : "note-files";

  return (
    <Collapse
      defaultOpen={files.length < 5}
      header={
        <h5 className="my-2 space-x-2 font-medium" data-test={`upload-${fileTypeKey}-text`}>
          <span>{t(`task.detail.${fileTypeKey}.title`)}</span>
          <span>({files.length})</span>
        </h5>
      }
    >
      {!hasFiles ? (
        <EmptyState
          icon={fileType === "delivery" ? FileBoxIcon : NotebookPen}
          title={t(`task.detail.${fileTypeKey}.title-no-files`)}
          subtitle={error ? t("error:common.server") : t(`task.detail.${fileTypeKey}.title-no-files-detail`)}
        />
      ) : (
        <table className="min-w-full table-fixed divide-y divide-gray-100 dark:divide-slate-600">
          <thead className="text-color-primary">
            <tr>
              <th scope="col" className="w-1/2 cursor-pointer py-3.5 pl-4 pr-3 font-semibold sm:pl-6">
                <div className="flex items-center">
                  <span data-test="upload-files-filename-label">{t("task.detail.files.fields.filename")}</span>
                </div>
              </th>
              <th
                scope="col"
                className="hidden w-1/2 cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6"
              >
                <div className="flex items-center">
                  <span data-test={`upload-${fileTypeKey}-delivery-time-label`}>
                    {t(`task.detail.${fileTypeKey}.fields.deliveryTime`)}
                  </span>
                  <BarsArrowDownIcon className="ml-3 size-4" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-color-primary divide-y divide-gray-200 dark:divide-slate-600">
            {files.map((file, index) => (
              <tr key={index} className="mb-0.5">
                <td className="text-nowrap px-3 py-4 xl:table-cell">
                  <div className="flex flex-col">
                    <span data-test={`upload-files-filename-${file.fileName}`}>{file.fileName}</span>
                    <span className="sm:hidden">{t("enum:dates.mediumDateTime", { date: file.creationTime })}</span>
                  </div>
                </td>
                <td
                  className="hidden whitespace-nowrap px-3 py-4 sm:table-cell"
                  data-test={`upload-files-delivery-time-${file.fileName}`}
                >
                  {t("enum:dates.mediumDateTime", { date: file.creationTime })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Collapse>
  );
};
